import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {RootService} from './+shared/services/root.service';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {NgSelectConfig} from '@ng-select/ng-select';
import {AuthService} from './+shared/services/auth.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {ManagementService} from './functional-modules/management/management.service';
import {zip} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {environment} from '../environments/environment';
import { MyCabinetService } from './functional-modules/my-cabinet/my-cabinet.service';
import { ToastContainerDirective } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  networkListener;
  networkStatus;

  constructor(public rootService: RootService,
              public router: Router,
              public authService: AuthService,
              public route: ActivatedRoute,
              private config: NgSelectConfig,
              public ngxSmartModalService: NgxSmartModalService,
              public managementService: ManagementService,
              public myCabinetService: MyCabinetService,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.config.notFoundText = 'Немає данних';
    zip(
      this.rootService.initialize$.pipe(filter((isReady) => isReady)),
      this.managementService.setSchoolSettings(),
      this.router.events.pipe(filter((e: RouterEvent) => e instanceof NavigationEnd))
    ).subscribe(() => {
      if (this.managementService.schoolSettings && this.managementService.schoolSettings.logo_url) {
        if (typeof this.managementService.schoolSettings.logo_url === 'string') {
          this._document.getElementById('appIcon').setAttribute('href', this.managementService.schoolSettings.logo_url);
        }
      } else {
        this._document.getElementById('appIcon').setAttribute('href', '/assets/images/icons/school-without-photo-icon.svg');
      }
      const fSegment = this.router.url.split('/')[1];
      
      if (!fSegment) {
        if (!this.authService.user) {
          this.router.navigateByUrl('/login');
        } else {
          console.log(this.authService.user.getHighestRole().getSectionPath());
          this.router.navigateByUrl(this.authService.user.getHighestRole().getSectionPath());
        }
      }
    });

    this.rootService.initData();
  }

  async ngOnInit() {
    this.rootService.roleChanging$.pipe(filter(role => !!role)).subscribe((role) => {
      if (role.isTeacher()) {
        this.myCabinetService.runLessonsRefresher(this.toastContainer);
      }
    });

    if (environment.is_mobile) {
      this._document.body.classList.add('mobile-app');
    }

    this.networkStatus = {connected: true};

    if (Capacitor.isPluginAvailable('Network')) {
      this.networkListener = Network.addListener('networkStatusChange', () => {
        document.location.reload();
      });

      this.networkStatus = await Network.getStatus();
    }
  }

  ngOnDestroy() {
    this.networkListener.remove();
  }

  isAcceptableNav() {
    const forbiddenSection = ['login', 'registration', 'password-recovery'];
    const fSegment = this.router.url.split('/')[1];

    return !forbiddenSection.some(p => fSegment.includes(p));
  }

}
