import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {SharedModule} from './+shared/shared.module';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {RegistrationComponent} from './registration/registration.component';
import {AjaxService} from './+shared/services/ajax.service';
import {RootService} from './+shared/services/root.service';
import {NavigationComponent} from './+shared/components/navigation/navigation.component';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {HomeComponent} from './home/home.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthService} from './+shared/services/auth.service';
import {SectionGuard} from './+shared/guards/section.guard';
import {RefreshTokenInterceptor} from './+shared/interceptors/refresh-token.interceptor';
import {AuthInterceptor} from './+shared/interceptors/auth.interceptor';
import {NoAuthGuard} from './+shared/guards/no-auth.guard';
import {NotFoundComponent} from './not-found/not-found.component';
import {ManagementService} from './functional-modules/management/management.service';
import {HelperService} from './+shared/services/helper.service';
import {GeneralInfoGuardGuard} from './+shared/guards/general-info-guard.guard';
import {AboutSchoolComponent} from './+shared/modules/about-school/about-school.component';
import {ClassesService} from './functional-modules/classes/classes.service';
import {RolePermissionGuard} from './+shared/guards/role-permission.guard';
import {LibraryService} from './functional-modules/library/library.service';
import {MyCabinetService} from './functional-modules/my-cabinet/my-cabinet.service';
import {UsersService} from './functional-modules/users/users.service';
import {StaffService} from './functional-modules/staff/staff.service';
import {ParentsService} from './functional-modules/parents/parents.service';
import {SectionsService} from './functional-modules/sections/sections.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {KitchenService} from './functional-modules/kitchen/kitchen.service';
import {AboutSchoolModule} from './+shared/modules/about-school/about-school.module';
import {DiactivateRouteGuard} from './+shared/guards/diactivate-route.guard';
import {SetNewPasswordComponent} from './password-recovery/set-new-password/set-new-password.component';
import {PupilsService} from './pupils/pupils.service';
import {FinancesService} from './functional-modules/management/finances/finances.service';
import {AppComponent} from './app.component';
import {IonicModule} from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PollsService } from './functional-modules/polls/polls.service';
import { MessengerService } from './functional-modules/messenger/messenger.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    PasswordRecoveryComponent,
    SetNewPasswordComponent,
    HomeComponent,
    NavigationComponent,
    NotFoundComponent,
    AboutSchoolComponent,
  ],
  imports: [
    SharedModule,
    AboutSchoolModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 5
    }),
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        data: {
          name: 'administration'
        },
        canActivate: [SectionGuard, GeneralInfoGuardGuard],
      },
      {
        path: 'teachers',
        loadChildren: () => import('./teachers/teachers.module').then(m => m.TeachersModule),
        data: {
          name: 'teacher'
        },
        canActivate: [SectionGuard, GeneralInfoGuardGuard],
      },
      {
        path: 'parents',
        loadChildren: () => import('./parents/parents.module').then(m => m.ParentsModule),
        data: {
          name: 'parent'
        },
        canActivate: [SectionGuard],
      },
      {
        path: 'pupils',
        loadChildren: () => import('./pupils/pupils.module').then(m => m.PupilsModule),
        data: {
          name: 'pupils'
        },
        canActivate: [SectionGuard],
      },
      {
        path: 'registration',
        component: RegistrationComponent,
        canActivate: [NoAuthGuard]
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [NoAuthGuard]
      },
      {
        path: 'password-recovery',
        component: PasswordRecoveryComponent,
        canActivate: [NoAuthGuard]
      },
      {
        path: 'password-recovery/step2/:key',
        component: SetNewPasswordComponent,
        canActivate: [NoAuthGuard]
      },
      {
        path: '**',
        component: NotFoundComponent
      },
      {
        path: 'no-found',
        component: NotFoundComponent
      }
    ]),
    BrowserModule,
    IonicModule.forRoot()
  ],
  providers: [
    PupilsService,
    HelperService,
    AuthService,
    AjaxService,
    RootService,
    SectionGuard,
    RolePermissionGuard,
    ManagementService,
    NoAuthGuard,
    GeneralInfoGuardGuard,
    DiactivateRouteGuard,
    UsersService,
    FinancesService,
    ClassesService,
    LibraryService,
    MyCabinetService,
    StaffService,
    ParentsService,
    MessengerService,
    SectionsService,
    KitchenService,
    PollsService,
    {provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
